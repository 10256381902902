import React from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import Kickoff from './components/kickoff.jsx';

window.App = {};

window.App.isMethodist = () => (
  get(App, 'student_form_data.school_id') == '5'
);

window.appendReactApp = (element, props = {}) => {
  const component = React.createElement(Kickoff, props)

  ReactDOM.render(
    component,
    element.appendChild(document.createElement('div'))
  )
}
