import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '../../util.js';
import classnames from 'classnames';
import {getUpgradeLink} from '../view_helpers.jsx';
import {getState, setState, setMetaState} from '../product_data.js';

const clickOption = e => {
  const t = e.target;
  const {option, attr} = t.dataset;
  const selected = t.classList.contains('selected');

  setState({
    [attr]: selected ? null : option
  });
};

const renderOption = (option, selected, attr) => {
  const key = `ft_option_${option}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  return (
    <button onClick={clickOption} data-option={option} data-attr={attr}
      key={key} type="button" className={classes}>
      {capitalize(option)}
    </button>
  )
};

const types = ['standard', 'deluxe'];
const new_or_nah = ['yes', 'no'];

const FutonSelector = () => {
  const futon_type = useStoreState(state => state.product_form.state.futon_type);
  const futon_new = useStoreState(state => state.product_form.state.futon_new);

  return (
    <div id='ft_opts_hold' className='mt-2'>
      <div className='mt-3 mb-1'>
        What futon style would you like?
      </div>

      {types.map(t => renderOption(t, t === futon_type, 'futon_type'))}

      {!!futon_type &&
        (
        <div>
          <div>
            If new units are still available, would you prefer to upgrade to a BRAND NEW futon? ({getUpgradeLink()})
          </div>
          {new_or_nah.map(n => renderOption(n, n === futon_new, 'futon_new'))}
        </div>
        )
      }
    </div>
  );
};


export default React.memo(FutonSelector);
