import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '../../util.js';
import classnames from 'classnames';
import {getUpgradeLink} from '../view_helpers.jsx';
import {getState, setState, setMetaState} from '../product_data.js';

const clickOption = e => {
  const t = e.target;
  const {option, attr} = t.dataset;
  const selected = t.classList.contains('selected');

  setState({
    [attr]: selected ? null : option
  });
};

const renderTvOption = (option, selected, attr) => {
  const key = `tv_option_${option}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  var txt;

  if (attr === 'tv_size') {
    txt = `${option} Inch`;
  } else {
    txt = capitalize(option);
  }


  return (
    <button onClick={clickOption} data-option={option} data-attr={attr}
      key={key} type="button" className={classes}>
      {txt}
    </button>
  )
};

const tv_sizes = ['32', '40'];
const yes_or_no = ['yes', 'no'];
const rent_or_purchase = ['rent', 'purchase'];

// after they select a TV size, another popup option saying:
// Would you like to have us hook up the TV for you? (additional fees may apply)

const TvSelector = () => {
  const tv_size = useStoreState(state => state.product_form.state.tv_size);
  const tv_purchase = useStoreState(state => state.product_form.state.tv_purchase);
  const wants_tv_hookup = useStoreState(state => state.product_form.state.wants_tv_hookup);

  const ismeth = App.isMethodist();

  const existing_sizes = ismeth ? ['32'] : tv_sizes;

  return (
    <div id='tv_opts_hold' className='mt-4'>
      <div className='mt-1 mb-1'>
        What size TV would you like?
      </div>

      {existing_sizes.map(o => renderTvOption(o, tv_size === o, 'tv_size'))}

      {!!tv_size && !ismeth &&
        <div>
          <div>
            Would you like to have us hook up the TV for you? ({getUpgradeLink()})
          </div>
          {yes_or_no.map(h => renderTvOption(h, h === wants_tv_hookup, 'wants_tv_hookup'))}
        </div>
      }
      {ismeth && !!tv_size &&
        <div>
          <div>Would you like to rent or purchase this TV?</div>
          {rent_or_purchase.map(o => renderTvOption(o, o === tv_purchase, 'tv_purchase'))}
        </div>
      }
    </div>
  );
};


export default React.memo(TvSelector);
