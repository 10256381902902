import React, {useEffect} from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback, Row, Container, Col } from 'reactstrap';
import { Formik } from 'formik';
import find from 'lodash/find';
import clone from 'lodash/clone';
import {isPresent, isDefined, isBlank} from '../../util.js';
import {getBuildingName, formatPhoneNumber, email_regexp, cntInts} from '../helpers.js';
import {getInitialValues} from '../people_data.js';

let phoneNeedsFormatting = false;

const not_required_fields = ['building_id', 'room_number', 'room_wing'];
function StudentForm() {
  useEffect(() => {
    return () => {
      App.resetStudentForm = null;
    }
  });

  return (
    <Formik
      initialValues={getInitialValues('student_form')}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!email_regexp.test(values.email)) {
          errors.email = 'Invalid email address';
        }

        const phone_int_cnt = cntInts(values.phone)
        const phone_int_cnt_btwn_10_and_16 = phone_int_cnt >= 10 && phone_int_cnt <= 16;
        if (!phone_int_cnt_btwn_10_and_16) {
          errors.phone = "Invalid phone number";
        }

        if (!values.first_name) {
          errors.first_name = 'Required';
        }

        if (!values.last_name) {
          errors.last_name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        setTimeout(() => {
          App.student_form_data = values;
          App.store.dispatch.setView('product_form');
        }, 1);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        handleReset
      }) => {
        const has_errors = Object.keys(errors).length > 0;
        const has_any_empty_values = isDefined(find(values, (val,key) =>{
          return (!not_required_fields.includes(key) && !val);
        }));

        App.resetStudentForm = () => {
          resetForm(getInitialValues('student_form'))
        };

        if (phoneNeedsFormatting && isBlank(errors.phone) && isPresent(values.phone)) {
          values.phone = formatPhoneNumber(values.phone) || values.phone;
          phoneNeedsFormatting = false;
        }

        const btn_disabled = has_any_empty_values || has_errors || isSubmitting;

        const email_invalid = touched.email && isPresent(errors.email);
        const first_name_invalid = touched.first_name && isPresent(errors.first_name);
        const last_name_invalid = touched.last_name && isPresent(errors.last_name);
        const phone_invalid = touched.phone && isPresent(errors.phone);
        const room_number_invalid = touched.room_number && isPresent(errors.room_number);

        const has_school = isPresent(values.school_id);
        const has_building = isPresent(values.building_id);

        const blurPhone = (e) => {
          handleBlur(e);

          phoneNeedsFormatting = true
        };

        var buildings, school_id;
        if (has_school) {
          school_id = parseInt(values.school_id);
          buildings = App.buildings.filter(b => b.school_id === school_id);
        }

        return (
          <Container>
            <h4 className='ml-1 mt-1'>Student Details</h4>
            <Form className='m-1' onSubmit={handleSubmit}>
              <Row>
                <Col sm='6'>
                  <FormGroup>
                    <Label for="first_name">Student First Name</Label>
                    <Input onChange={handleChange} onBlur={handleBlur} name="first_name" id="first_name" placeholder="first name" invalid={first_name_invalid} value={values.first_name} />
                    <FormFeedback>{errors.first_name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm='6'>
                  <FormGroup>
                    <Label for="last_name">Student Last Name</Label>
                    <Input onChange={handleChange} onBlur={handleBlur} name="last_name" id="last_name" placeholder="last name" invalid={last_name_invalid} value={values.last_name} />
                    <FormFeedback>{errors.last_name}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="email">Student Email</Label>
                <Input onChange={handleChange} onBlur={handleBlur} type="email" name="email" id="email" placeholder="example@yourschool.edu" invalid={email_invalid} value={values.email} />
                <FormText color="muted">Please use your school assigned email address. If you do not have it yet, you may use your personal email address instead.</FormText>
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="yearSelect">Year</Label>
                <Input type="select" name="year" id="buildingSelect" onChange={handleChange} value={values.year}>
                  <option>Freshman</option>
                  <option>Sophmore</option>
                  <option>Junior</option>
                  <option>Senior</option>
                  <option>Graduate</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="phone">Student Cell Phone #</Label>
                <Input onChange={handleChange} onBlur={blurPhone} type="phone" name="phone" id="phone" placeholder="phone number" invalid={phone_invalid} value={values.phone} />
                <FormFeedback>{errors.phone}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="schoolSelect">Select Your School</Label>
                <Input type="select" name="school_id" id="schoolSelect" onChange={handleChange} value={values.school_id}>
                  <option value=''></option>
                  <option value="1">William & Mary</option>
                  <option value="2">University of Richmond</option>
                  <option value="5">Methodist</option>
                </Input>
              </FormGroup>

              {has_school &&
                <React.Fragment>
                  <FormGroup>
                    <Label for="buildingSelect">Select Your Building</Label>
                    <Input type="select" name="building_id" id="buildingSelect" onChange={handleChange} value={values.building_id}>
                      <option value="">I don't know yet</option>
                      {buildings.map(b => (
                        <option key={`building_opt_${b.id}`} value={`${b.id}`}>
                          {getBuildingName(b)}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  {has_building &&
                    <React.Fragment>
                      <FormGroup>
                        <Label for="room_number">Your Room Number</Label>
                        <Input onChange={handleChange} onBlur={handleBlur} type="text" name="room_number" id="room_number" placeholder="room number" invalid={room_number_invalid} value={values.room_number} />
                        <FormFeedback>{errors.room_number}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="room_wing">Wing/Suite Letter</Label>
                        <Input onChange={handleChange} onBlur={handleBlur} name="room_wing" id="room_wing" placeholder="Wing/Suite Letter" value={values.room_wing} />
                        <FormText>
                          If you have a letter designation as part of your housing assignment (eg 'A'), please enter it here
                        </FormText>
                      </FormGroup>
                    </React.Fragment>
                  }
                </React.Fragment>
              }

              <div className='d-flex justify-content-between'>
                <div></div>
                <Button type='submit' color="primary" disabled={btn_disabled}>Next</Button>
              </div>
            </Form>
          </Container>
        )

      }}
    </Formik>
  );
}

export default React.memo(StudentForm);
