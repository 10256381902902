import React from 'react';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { useStoreState } from 'easy-peasy';
import clone from 'lodash/clone';
import {initial_product_form_data} from '../product_data';

const toggleDrawer = () => {
  App.store.dispatch.app_bar.toggle();
};

const resetForm = () => {
  App.store.dispatch.product_form.setState(clone(initial_product_form_data));
  App.store.dispatch.setView('student_form');
}

function AppBar() {
  const open = useStoreState(state => state.app_bar.open);

  return (
    <Navbar color="dark" dark>
      <Container>
        <NavbarBrand href="/" className="mr-auto">Dorms Direct Reservations</NavbarBrand>
      </Container>
    </Navbar>
  );
}

export default React.memo(AppBar);
