import React from 'react';
import AppBar from './app_bar.jsx';
import StudentForm from './student_form.jsx';
import ParentForm from './parent_form.jsx';
import ProductForm from './product_form.jsx';
import Success from './success.jsx';

import { useStoreState } from 'easy-peasy';

export default function Main() {
  const view = useStoreState(state => state.view) || 'student_form';

  return (
    <React.Fragment>
      <AppBar />

      {view === 'student_form' ? <StudentForm /> : null}
      {view === 'product_form' ? <ProductForm /> : null}
      {view === 'parent_form' ? <ParentForm /> : null}
      {view === 'success' ? <Success /> : null }
    </React.Fragment>
  )
}
