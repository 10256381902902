import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback, Row, Container, Col } from 'reactstrap';
import { Formik } from 'formik';
import find from 'lodash/find';
import clone from 'lodash/clone';
import {isPresent, isDefined, isBlank} from '../../util.js';
import {formatPhoneNumber, email_regexp} from '../helpers.js';
import {getInitialValues} from '../people_data.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import submitForm from '../submit_form.js';

let phoneNeedsFormatting = false;

const clickBack = () => {
  App.store.dispatch.setView('product_form');
};

function ParentForm() {
  return (
    <Formik
      initialValues={getInitialValues('parent_form')}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!email_regexp.test(values.email)) {
          errors.email = 'Invalid email address';
        }

        // const phone_int_cnt = cntInts(values.phone)
        // const phone_int_cnt_btwn_10_and_14 = phone_int_cnt >= 10 && phone_int_cnt <= 14;
        // if (!phone_int_cnt_btwn_10_and_14) {
        //   errors.phone = "Invalid phone number";
        // }

        if (!values.first_name) {
          errors.first_name = 'Required';
        }

        if (!values.last_name) {
          errors.last_name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        App.parent_form_data = values;
        submitForm().then((json) => {;
          App.store.dispatch.setView('success');
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset
        /* and other goodies */
      }) => {
        const has_errors = Object.keys(errors).length > 0;

        if (phoneNeedsFormatting && isBlank(errors.phone) && isPresent(values.phone)) {
          values.phone = formatPhoneNumber(values.phone) || values.phone;
          phoneNeedsFormatting = false;
        }

        const btn_disabled = has_errors || isSubmitting;

        const email_invalid = touched.email && isPresent(errors.email);
        const first_name_invalid = touched.first_name && isPresent(errors.first_name);
        const last_name_invalid = touched.last_name && isPresent(errors.last_name);
        const phone_invalid = touched.last_name && isPresent(errors.last_name);

        const blurPhone = (e) => {
          handleBlur(e);

          phoneNeedsFormatting = true
        };

        const btntxt = isSubmitting ? 'Submitting...' : 'Submit your order!';
        return (
          <Container>
            <Button color="primary" className="d-flex align-items-center mt-3" onClick={clickBack} type="button">
              <ArrowBackIcon className="mr-1"/>
              Back
            </Button>
            <h4 className='ml-1 mt-1'>Parent Details</h4>
            <Form className='m-1' onSubmit={handleSubmit}>
              <Row>
                <Col sm='6'>
                  <FormGroup>
                    <Label for="first_name">Parent First Name</Label>
                    <Input onChange={handleChange} onBlur={handleBlur} name="first_name" id="first_name" placeholder="first name" invalid={first_name_invalid} value={values.first_name} />
                    <FormFeedback>{errors.first_name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm='6'>
                  <FormGroup>
                    <Label for="last_name">Parent Last Name</Label>
                    <Input onChange={handleChange} onBlur={handleBlur} name="last_name" id="last_name" placeholder="last name" invalid={last_name_invalid} value={values.last_name} />
                    <FormFeedback>{errors.last_name}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="phone">Parent Cell Phone # (optional)</Label>
                <Input onChange={handleChange} onBlur={blurPhone} type="phone" name="phone" id="phone" placeholder="phone number" value={values.phone} />
                <FormFeedback>{errors.phone}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label for="email">Parent Email</Label>
                <Input onChange={handleChange} onBlur={handleBlur} type="email" name="email" id="email" placeholder="you@example.com" invalid={email_invalid} value={values.email} />
                <FormFeedback>{errors.email}</FormFeedback>
              </FormGroup>

              <div className='d-flex justify-content-between'>
                <div></div>
                <Button type='submit' color="primary" disabled={btn_disabled}>
                  {btntxt}
                </Button>
              </div>
            </Form>
          </Container>
        )

      }}
    </Formik>
  );
}

export default React.memo(ParentForm);
