import React from 'react';

import classnames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '../../util.js';

import ArrivalDatePicker from './arrival_date_picker.jsx';
import {getState, setState, setMetaState} from '../product_data.js';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const handleChange = (e) => {
  const name = e.target.getAttribute('name');

  const state = {};

  const val = e.target.checked;

  if (val) {
    state.arrival_date_at_ts = null;
  }

  state[name] = val;

  setState(state);
};

const clickOption = e => {
  const t = e.target;
  const {option, attr} = t.dataset;
  const selected = t.classList.contains('selected');

  setState({
    [attr]: selected ? null : option
  });
};

const renderOption = (option, selected, attr) => {
  const key = `prior_option_${option}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  return (
    <button onClick={clickOption} data-option={option} data-attr={attr}
      key={key} type="button" className={classes}>
      {capitalize(option)}
    </button>
  )
};

const options = ['yes', 'no'];

function ArrivalDateSection() {
  const arrival_date_at_ts = useStoreState(state => state.product_form.state.arrival_date_at_ts);
  const arriving_prior = useStoreState(state => state.product_form.state.arriving_prior);
  const dunno_arrival_date = useStoreState(state => state.product_form.state.dunno_arrival_date);

  return (
    <div className='mt-4'>
      <h6>Are you planning to arrive PRIOR to your school's official Freshmen Move-in Day?</h6>

      {options.map(o => renderOption(o, arriving_prior === o, 'arriving_prior'))}

      {arriving_prior === 'yes' &&
        <div>
          <ArrivalDatePicker />

          <FormControlLabel
            control={
              <Checkbox
                name='dunno_arrival_date'
                checked={dunno_arrival_date}
                onChange={handleChange}
                value='checked'
                color="primary"
              />
            }
            label="I'm not sure when I'll arrive"
          />
        </div>
      }
    </div>
  );
}

export default React.memo(ArrivalDateSection);
