import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback, Row, Container, Col } from 'reactstrap';
import find from 'lodash/find';
import clone from 'lodash/clone';
import without from 'lodash/without';
import reject from 'lodash/reject';
import {isPresent, isDefined, isBlank} from '../../util.js';
import {getBuildingName} from '../helpers.js';
import {products, main_prods, bl_addons, upgrades_href, getState, setState, setMetaState} from '../product_data.js';
import {getUpgradeLink} from '../view_helpers.jsx';
import { useStoreState } from 'easy-peasy';
import classnames from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ErrorsAlert from './errors_alert.jsx';
import FutonSelector from './futon_selector.jsx';
import HeadboardSelector from './headboard_selector.jsx';
import TvSelector from './tv_selector.jsx';
import FridgeSelector from './fridge_selector.jsx';
import ArrivalDateSection from './arrival_date_section.jsx';

import validateProductForm from '../validate_product_form.js';

const clickProduct = (e) => {
  const short = e.target.dataset.short;
  const product = products.find(p => p.short === short);
  const product_shorts = getState().product_shorts;

  if (product_shorts.includes(short)) {
    let shorts_to_rm = [short];

    if (short === 'BL') {
      shorts_to_rm = shorts_to_rm.concat(bl_addons.map(p => p.short));
    }

    setState({
      product_shorts: without(product_shorts, ...shorts_to_rm)
    });
  } else {
    setState({
      product_shorts: [...product_shorts, short]
    });
  }
};

const renderProduct = (prod, selected) => {
  const key = `prod_button_${prod.short}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  return (
    <button onClick={clickProduct} data-short={prod.short}
      key={key} type="button" className={classes}>
      {prod.name}
    </button>
  )
};

const clickBack = () => {
  App.store.dispatch.setView('student_form');
};

const handleChange = (e) => {
  const name = e.target.getAttribute('name');
  let val = e.target.value;

  const state = {[name]: val};

  setState(state);
};

const handleSubmit = e => {
  e.preventDefault();

  const errors = validateProductForm();

  if (errors.length) {
    setMetaState({errors: errors});
  } else {
    App.store.dispatch.setView('parent_form');
  }
};

const clickMesh = selected => {
  setState({
    wants_mesh: !selected
  });
};

const renderMeshButton = (wants_mesh) => {
  const selected = wants_mesh;
  const key = `sr_mesh`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  return (
    <button onClick={() => clickMesh(selected)} key={key} type="button" className={classes}>
      Mesh Cover
    </button>
  )
};

const bad_meth_prods = ['FT', 'ES'];

const ProductForm = () => {
  const {product_shorts, tv_option, special_instructions, arriving_prior, wants_mesh} = useStoreState(state => state.product_form.state);

  const ismeth = App.student_form_data.school_id == '5';

  const real_prods = ismeth ?
    (reject(main_prods, p => bad_meth_prods.includes(p.short) ))
    : main_prods

  const has_loft = product_shorts.includes('BL');
  const has_tv = product_shorts.includes('TV');
  const has_ft = product_shorts.includes('FT');
  const has_hb = product_shorts.includes('HB');
  const has_sr = product_shorts.includes('SR');
  const has_mf = product_shorts.includes('MF');

  const btn_enabled = !!product_shorts.length && !!arriving_prior;

  return (
    <Container className="pt-2">
      <Form onSubmit={handleSubmit}>
        <Button color="primary" className="d-flex align-items-center" onClick={clickBack} type="button">
          <ArrowBackIcon className="mr-1"/>
          Back
        </Button>
        <div className='product_hold mt-2'>
          <h6>Please select your items:</h6>
          {real_prods.map(p => renderProduct(p, product_shorts.includes(p.short)))}
          {has_loft &&
            <div>
              <div className='mt-2 mb-1'>
                Would you like to add a safety rail or shelf to your bedloft? ({getUpgradeLink()})
              </div>
              {bl_addons.map(p => renderProduct(p, product_shorts.includes(p.short)))}
            </div>
          }
          {has_sr &&
            <div>
              <div className='mt-2 mb-1'>
                Would you like to add a pocketed mesh cover to your Safety Rail?  ({getUpgradeLink()})
              </div>
              {renderMeshButton(wants_mesh)}
            </div>
          }

          {ismeth && has_mf && <FridgeSelector />}
          {has_tv && <TvSelector />}
          {has_ft && <FutonSelector /> }
          {has_hb && <HeadboardSelector /> }
        </div>

        <ArrivalDateSection />

        <FormGroup className='mt-4'>
          <Label for="special_instructions">Special Instructions</Label>
          <Input placeholder='Special instructions' value={special_instructions} onChange={handleChange} type="textarea" name="special_instructions" id="special_instructions" />
          <FormText color="muted">
            Anything else you want to let us know about your order?  Did you want to add on an additional bedloft or headboard  for your roommate? Let us know here.
          </FormText>
        </FormGroup>

        <ErrorsAlert />

        <div className='d-flex justify-content-end mb-4'>
          <Button disabled={!btn_enabled} color="primary" type="submit">
            Next
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default React.memo(ProductForm);
