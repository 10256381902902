import React from 'react';
import Main from './main.jsx';

import clone from 'lodash/clone';

import { action, createStore, StoreProvider } from 'easy-peasy';
import {processBuildings} from '../helpers.js';
import {initial_product_form_data, initial_product_form_metadata} from '../product_data.js';

const toggler = () => ({
  open: false,
  toggle: action((state) => {
    state.open = !state.open;
  })
})

const setState = (initialState = {}) => ({
  state: initialState,
  setState: action((state, new_state) => {
    Object.assign(state.state, new_state);
  })
})

export default function Kickoff(props = {}) {
  const view = 'student_form';

  const product_form = clone(initial_product_form_data);

  const obj = {
    product_form: product_form,
    meta_product_form: initial_product_form_metadata
  };

  Object.assign(obj, props);

  Object.keys(obj).forEach((k) => {
    obj[k] = setState(obj[k]);
  });

  obj.app_bar = toggler();

  obj.view = view;
  obj.setView = action((state, next_view) => {
    state.view = next_view;
  });

  const store = createStore(obj);

  App.buildings = processBuildings(App.buildings);

  App.store = store;

  return (
    <StoreProvider store={store}>
      <Main></Main>
    </StoreProvider>
  )
}
