import {isBlank} from '../util.js';

const validateProductForm = () => {
  const errors = [];

  const {
    product_shorts,
    arrival_date_at_ts,
    arriving_prior,
    special_instructions,
    dunno_arrival_date,
    futon_type,
    futon_new,
    headboard_type,
    headboard_color,
    tv_size,
    wants_tv_hookup
  } = App.store.getState().product_form.state;

  const has_tv = product_shorts.includes('TV');
  const has_ft = product_shorts.includes('FT');
  const has_hb = product_shorts.includes('HB');
  const has_sr = product_shorts.includes('SR');
  const ismeth = App.isMethodist();

  if (has_ft) {
    if (isBlank(futon_type)) {
      errors.push('Please select a futon style');
    } else if (isBlank(futon_new)) {
      errors.push("Please select above: Would you like a to upgrade to a new futon?");
    }
  }

  if (has_hb) {
    if (isBlank(headboard_type)) {
      errors.push('Please select a headboard style');
    } else if (headboard_type === 'leather' && isBlank(headboard_color)) {
      errors.push('Please select a headboard color');
    }
  }

  if (has_tv) {
    if (isBlank(tv_size)) {
      errors.push("Please select a TV size");
    } else if (!ismeth && isBlank(wants_tv_hookup)) {
      errors.push("Please select whether you'd like to hook the TV up for you");
    }
  }

  return errors;
};

export default validateProductForm;
