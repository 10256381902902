import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '../../util.js';
import classnames from 'classnames';
import {getUpgradeLink} from '../view_helpers.jsx';
import {getState, setState, setMetaState} from '../product_data.js';

const clickOption = e => {
  const t = e.target;
  const {option, attr} = t.dataset;
  const selected = t.classList.contains('selected');

  setState({
    [attr]: selected ? null : option
  });
};

const renderOption = (option, selected, attr) => {
  const key = `ft_option_${option}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  return (
    <button onClick={clickOption} data-option={option} data-attr={attr}
      key={key} type="button" className={classes}>
      {capitalize(option)}
    </button>
  )
};

const types = ["pearl", "linen", "leather", "custom"];
const colors = ["brown", "black", "white"];

const hblink = 'http://dormsdirect.com/headboards.html';

const HeadboardSelector = () => {
  const headboard_type = useStoreState(state => state.product_form.state.headboard_type);
  const headboard_color = useStoreState(state => state.product_form.state.headboard_color);

  return (
    <div id='hb_opts_hold' className='mt-4'>
      <h6>Headboards are for purchase <i>only</i>!</h6>

      <div className='mt-3 mb-1'>
        What headboard style would you like?
        <a className='ml-1' href={hblink} target="_blank">View our headboards here</a>
      </div>

      {types.map(t => renderOption(t, t === headboard_type, 'headboard_type'))}

      {headboard_type === 'leather' &&
        <div>
          <div>
            What color leather would you like?
          </div>
          {colors.map(c => renderOption(c, c === headboard_color, 'headboard_color'))}
        </div>
      }

      {headboard_type === 'custom' &&
        <h6>
          Once we have verified your order, one of our Customer Service Reps will contact you to discuss your Custom Headboard design!
        </h6>
      }
    </div>
  );
};


export default React.memo(HeadboardSelector);
