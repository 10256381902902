import sortBy from 'lodash/sortBy';
import {isPresent} from '../util.js';

export const email_regexp = /^[^@\s]+@[^@\s]+$/i;

export const getBuildingName = (b) => {
  if (isPresent(b.name_in_submissions)) {
    return b.name_in_submissions
  }

  return b.name;
};

export const cntInts = (str) => {
  return str.replace(/[^0-9]/g,"").length;
};

export const processBuildings = (buildings) => {
  var building;
  for(let i = 0; i < buildings.length; i++) {
    building = buildings[i];
    building.real_name = getBuildingName(building);
  }

  return sortBy(buildings, b => b.real_name);
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
};
