import assign from 'lodash/assign';
import without from 'lodash/without';
import {postData} from '../fetch_helpers.js';

const prependers = ['first_name', 'last_name', 'phone', 'email'];

const process_person = (obj, name) => {
  const new_obj = {};

  for (let [key, value] of Object.entries(obj)) {
    if (prependers.includes(key)) {
      new_obj[`${name}_${key}`] = value;
    } else if (/_id$/.test(key)) {
      new_obj[key] = value ? parseInt(value) : null;
    } else {
      new_obj[key] = value;
    }
  };

  return new_obj;
};

const bl_prods = ['SR', 'BS', 'MC'];

const process_product = (obj, name) => {
  const new_obj = {};

  for (let [key, value] of Object.entries(obj)) {
    value = value === 'yes' ? true : (value === 'no' ? false : value);
    value = value === 'purchase' ? true : (value === 'rent' ? false : value);

    new_obj[key] = value;
  };

  let shorts = obj.product_shorts;

  const has_bl = shorts.includes('BL');
  const has_sr = shorts.includes('SR');

  if (!has_bl) {
    shorts = without(shorts, ...bl_prods);
  }

  if (!has_sr) {
    shorts = without(shorts, 'MC');
  }

  new_obj.product_shorts = shorts;

  return new_obj;
};

const submitForm = () => {
  const state = App.store.getState();

  const student_data = process_person(App.student_form_data, 'student');
  const parent_data = process_person(App.parent_form_data, 'parent');
  const product_data = process_product(state.product_form.state);

  const json = {};

  assign(json, student_data, product_data, parent_data);

  const params = {reservation: json};

  return postData(`/create_reservation.json`, params)
    .catch(error => alert('an error occurred'))
    .then((json) => {
      return json;
    });
};

export default submitForm;
