import React from 'react';

import { FormText } from 'reactstrap';
import { useStoreState } from 'easy-peasy';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const handleDateChange = (date) => {
  App.store.dispatch.product_form.setState({
    arrival_date_at_ts: date ? date.getTime() : date
  });
};

function ArrivalDatePicker() {
  const arrival_date_at_ts = useStoreState(state => state.product_form.state.arrival_date_at_ts);

  const arrival_date_at = arrival_date_at_ts ? new Date(arrival_date_at_ts) : null;

  return (
    <div className='d-flex align-items-center arrival-date-picker-hold'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{minWidth: 222}}
          margin="normal"
          id="mui-pickers-date"
          label="Planned Date of Arrival"
          value={arrival_date_at}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <FormText color="muted" className='ml-2'>
        This is supposed to be your move in date. We will attempt to deliver everything by this date, but it is not guaranteed.
      </FormText>
    </div>
  );
}

export default React.memo(ArrivalDatePicker);
