import React from 'react';
import { Alert } from 'reactstrap';
import map from 'lodash/map';
import { useStoreState } from 'easy-peasy';

const renderError = (text, attr) => {
  const key = `${attr}_error`;

  return (
    <li key={key}>{text}</li>
  )
};

const onDismiss = () => {
  App.store.dispatch.meta_product_form.setState({errors: {}});
};

const ErrorsAlert = () => {
  const errors = useStoreState(state => state.meta_product_form.state.errors);

  const has_errors = !!Object.keys(errors).length;

  return (
    <Alert color="danger" isOpen={has_errors} toggle={onDismiss}>
      <div>The following errors occurred:</div>
      <ul>
        {map(errors, renderError)}
      </ul>
    </Alert>
  );
}

export default React.memo(ErrorsAlert);
