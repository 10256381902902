export const products = [
  {
    name: 'Bedloft',
    short: 'BL'
  },
  {
    name: 'Microfridge',
    short: 'MF'
  },
  {
    name: 'Electronic Safe',
    short: 'ES'
  },
  {
    name: 'Futon',
    short: 'FT'
  },
  {
    name: 'HD Flatscreen TV',
    short: 'TV'
  },
  {
    name: 'Headboard',
    short: 'HB',
    purchase: true
  },
  {
    name: 'Safety Rail',
    short: 'SR',
    depends: 'BL'
  },
  {
    name: 'Bedshelf',
    short: 'BS',
    depends: 'BL'
  }
];


export const getState = () => {
  return App.store.getState().product_form.state;
};

export const setState = (state) => {
  App.store.dispatch.product_form.setState(state);
};

export const setMetaState = (state) => {
  App.store.dispatch.meta_product_form.setState(state);
};

export const main_prods = products.filter(p => !p.depends);
export const bl_addons = products.filter(p => p.depends === 'BL');

export const upgrades_href = 'http://dormsdirect.com/upgrades.html';

const arrival_date = new Date('2019-07-18T13:11:54');

export const initial_product_form_data = {
  product_shorts: [],
  arrival_date_at_ts: null, //arrival_date.getTime(),
  arriving_prior: null,
  special_instructions: '',
  dunno_arrival_date: false,
  futon_type: null,
  futon_new: null,
  headboard_type: null,
  headboard_color: null,
  tv_size: null,
  wants_tv_hookup: null,
  tv_purchase: null,
  mf_purchase: null
};

export const initial_product_form_metadata = {
  errors: {},
  date_selected: false
};

