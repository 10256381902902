import clone from 'lodash/clone';

const initialValues = {
  student_form: {
    email: '', first_name: '', last_name: '', phone: '', school_id: "", building_id: "", room_number: '', room_wing: '', year: 'Freshman'
  },
  parent_form: {
    email: '', first_name: '', last_name: '', phone: ''
  }
}

export const getInitialValues = (store) => {
  if (App[`${store}_data`]) {
    return App[`${store}_data`];
  } else {
    return clone(initialValues[store]);
  }
};
