import React, {useState} from 'react';
import { useStoreState } from 'easy-peasy';
import { capitalize } from '../../util.js';
import classnames from 'classnames';
import {getUpgradeLink} from '../view_helpers.jsx';
import {getState, setState, setMetaState} from '../product_data.js';

const clickOption = e => {
  const t = e.target;
  const {option, attr} = t.dataset;
  const selected = t.classList.contains('selected');

  setState({
    [attr]: selected ? null : option
  });
};

const renderFridgeOption = (option, selected, attr) => {
  const key = `tv_option_${option}`;

  const classes = classnames('btn muh-btn', {
    'btn-light': !selected,
    'btn-info': selected,
    'selected': selected,
    'unselected': !selected
  });

  const txt = capitalize(option);

  return (
    <button onClick={clickOption} data-option={option} data-attr={attr}
      key={key} type="button" className={classes}>
      {txt}
    </button>
  )
};

const rent_or_purchase = ['rent', 'purchase'];

const FridgeSelector = () => {
  const mf_purchase = useStoreState(state => state.product_form.state.mf_purchase);

  const ismeth = App.isMethodist();

  if (!ismeth) return null;

  return (
    <div id='mf_opts_hold' className='mt-4'>
      <div className='mt-1 mb-1'>
        Would you like to rent or purchase your Microfridge?
      </div>

      {rent_or_purchase.map(o => renderFridgeOption(o, mf_purchase === o, 'mf_purchase'))}
    </div>
  );
};


export default React.memo(FridgeSelector);
