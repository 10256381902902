import React from 'react';
import { Container } from 'reactstrap';

function Success() {
  return (
    <Container>
      <h5 style={{textAlign: 'center'}} className='mt-3'>
        Success!  After we have verified your reservation, we will send you an invoice within the next few days so keep an eye on your email. We'll be in touch!
      </h5>
    </Container>
  )
}

export default React.memo(Success);
